
.ui {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    @media screen and (min-width: 48rem) {
        grid-template-columns: 20rem 1fr;
        justify-items: initial;
    }
}

.tetris {
    width: 20rem;
    height: 40rem;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    border: 2px solid #222;

    @media screen and (max-width: 48rem) and (max-height: 60rem) {
        width: 16rem;
        height: 32rem;
    }
}

@mixin grad($base, $light: white, $w: 30%) {
    background: linear-gradient(135deg, $light, $base (40% + $w), $base 80%);
}

.tetris_block {
    background-color: #444;
    border: 1px solid #222;

    [data-gameover] & {
        background-color: #666;
    }

    &[data-kind="i"] {
        @include grad(aqua);
        border-color: #088;
    }

    &[data-kind="o"] {
        @include grad(#fcff44, white, 10%);
        border-color: #a50;
    }

    &[data-kind="s"] {
        @include grad(#0f0);
        border-color: #090;
    }

    &[data-kind="z"] {
        @include grad(#f22, white);
        border-color: #a00;
    }

    &[data-kind="j"] {
        @include grad(#66f, #ddf);
        border-color: #006;
    }

    &[data-kind="l"] {
        @include grad(#dd9900);
        border-color: #a40;
    }

    &[data-kind="t"] {
        @include grad(#f0f, white);
        border-color: #a0a;
    }
}
    
.controller_wrap {
    justify-content: start;
    align-content: end;
}

.controller {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas:    "h h h"
                            "l t r"
                            "d d d";

    max-width: 10rem;

    div {
        text-align: center;
        padding: .5rem;
        margin: .5rem;
    }

    [data-key="harddrop"] {
        grid-area: h;
    }

    [data-key="left"] {
        grid-area: l;
    }

    [data-key="right"] {
        grid-area: r;
    }

    [data-key="down"] {
        grid-area: d;
    }

    [data-key="rotate"] {
        grid-area: t;
    }
}
